import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { useSelector } from "react-redux";
import { ReactComponent as Music } from "../../assets/svgs/Music.svg";
import { ReactComponent as Sport } from "../../assets/svgs/Sport.svg";
import { ReactComponent as CryptoFilled } from "../../assets/svgs/CryptoFilled.svg";
import { ReactComponent as RecordCircle } from "../../assets/svgs/RecordCircle.svg";
import { ReactComponent as ClockFilled } from "../../assets/svgs/ClockFilled.svg";
import { ReactComponent as EditFilled } from "../../assets/svgs/EditFilled.svg";
import { ReactComponent as WarningFilled } from "../../assets/svgs/WarningFilled.svg";
import Footer from "../../Component/FooterNew/FooterNew";
import SideBar from "../../Component/SideBar/SideBar";
import Header from "../../Component/Header/Header";
import Text from "../../Component/Common/Text";
import Tags from "../../Component/Common/Tags/Tags";
import "./Home.css";
import NoImage from "../../assets/png/NoImage.png";
import ButtonPill from "../../Component/Common/Buttons/ButtonPill";
import NoData from "../../Component/Common/NoData/NoData";
import Accordion from "../../Component/Common/Accordion/Accordion";
import {
  EventCreationAccordion1,
  EventCreationAccordion2,
  ManagingEventAccordion1,
  ManagingEventAccordion2,
  Marketing1,
  Marketing2,
  TicketGuard1,
  TicketGuard2,
  Billing1,
  Billing2,
  VenueApp1,
  VenueApp2,
} from "../../constants/Content";
import { getEventList, getDraftEventExist, getInactiveDraftEventList } from "../../services/api";
import Settings from "../Settings/Settings";

function Home() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = useSelector((state) => state.userData?.userData);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [eventOptions, setEventOptions] = useState([
    { name: "Upcoming", id: 1 },
    { name: "Expired", id: 2 },
    { name: "Draft", id: 3 }
  ]);

  const HelpOptions = [
    { name: "Event creation", id: 1 },
    { name: "Managing events", id: 2 },
    {name: "Marketing", id: 3},
    { name: "TicketGuard™", id: 4 },
    {name: "Billing", id: 5},
    {name: "Venue App", id: 6},
  ];

  const [assetOptions, setAssetOptions] = useState([]);
  const [selectedSegmentIndexAsset, setSelectedSegmentIndexAsset] = useState(0);
  const [selectedSegmentAsset, setSelectedSegmentAsset] = useState("All");
  const [selectedSegmentIndexEvent, setSelectedSegmentIndexEvent] = useState(0);
  const [selectedSegmentIndexHelp, setSelectedSegmentIndexHelp] = useState(0);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [masterEventList, setMasterEventList] = useState([]);

  useEffect(() => {
    location?.state?.selectedIndex !== undefined &&
      setSelectedOptionIndex(location?.state?.selectedIndex);
  }, []);

  

  useEffect(() => {
    eventData(
      selectedSegmentIndexEvent === 0
        ? "Current"
        : selectedSegmentIndexEvent === 1
        ? "Past"
        : "All",
      selectedSegmentAsset?.name
    );
  }, [selectedSegmentIndexEvent, selectedSegmentAsset]);

  // useEffect(() => {
  //   setTimeout(async () => {
  //     checkForDraft();
  //   }, 1000);
  // }, []);

  const checkForDraft = async () => {
    const result = await getDraftEventExist({});
    result?.response?.count > 0 &&
      eventOptions.length === 2 &&
      setEventOptions((prevItems) => [...prevItems, { name: "Draft", id: 3 }]);
  };

  // const checkForDraft = async () => {
  //   const result = await getEventList({
  //     isPublished: false,
  //     type: "All",
  //   });
  //   result?.response?.length > 0 &&
  //     eventOptions.length === 2 &&
  //     setEventOptions((prevItems) => [...prevItems, { name: "Draft", id: 3 }]);
  // };

  const eventData = async (type, category) => {
    setIsLoading(true);
    const result = await getEventList({
      isPublished: selectedSegmentIndexEvent == 2 ? false : true,
      category: category !== "All" ? category : "",
      type: type,
    });
    if (result?.response?.status === 401) {
      localStorage.clear();
      navigate("/Login");
      window.alert("The session has expired. Please log in again.");
    }
    if (result.status) {
      if(selectedSegmentIndexEvent === 2) {
        getInactiveDraftEventListRequest(result.response);
      }
      else
      {
        setAssetOptions(getAssetArray(result.response));
      setMasterEventList(result.response);
      }
      
    } else {
      setAssetOptions([{ name: "All", id: 1 }]);
      setMasterEventList([]);
    }
    setTimeout(async () => {
      setIsLoading(false);
    }, 2500);
  };

  const getInactiveDraftEventListRequest = async (existingDraftList) => {
    const result = await getInactiveDraftEventList({});
    if (result.status) {
      setAssetOptions(getAssetArray(existingDraftList));
      setMasterEventList([...existingDraftList, ...result.response]);
    } else {
      setAssetOptions(getAssetArray(existingDraftList));
      setMasterEventList(existingDraftList);
    }
    setIsLoading(false);
  };

  // Function to get the asset array
  const getAssetArray = (data) => {
    const assetMap = {};
    data.forEach((item) => {
      const { eventCategoryNames } = item;
      assetMap[eventCategoryNames[0]] = (assetMap[eventCategoryNames[0]] || 0) + 1;
    });

    const assetArray = Object.entries(assetMap).map(([name, length]) => ({
      name,
      length,
    }));
    assetArray.unshift({ name: "All", length: data.length });
    return assetArray;
  };

  const segmentBtnPressedAsset = (index, item) => {
    if (index != selectedSegmentIndexAsset) {
      setSelectedSegmentIndexAsset(index);
      setSelectedSegmentAsset(item);
    }
  };

  const segmentBtnPressedEvent = (index) => {
    if (index != selectedSegmentIndexEvent) {
      setSelectedSegmentIndexEvent(index);
      setSelectedSegmentIndexAsset(0);
      setSelectedSegmentAsset("All");
    }
  };

  const segmentBtnPressedHelp = (index) => {
    if (index != selectedSegmentIndexHelp) {
      setSelectedSegmentIndexHelp(index);
    }
  };

  const eventList = masterEventList.filter((item) => {
    const category = item.eventCategoryNames && item.eventCategoryNames[0];
    if (selectedSegmentAsset !== "All") {
      return category && category?.includes(selectedSegmentAsset);
    } else {
      return masterEventList;
    }
  });

  useEffect (() => {
    console.log("eventList12323232", eventList);
  }, [eventList]);

  function getOrdinalSuffix(num) {
    const suffixes = ["th", "st", "nd", "rd"];
    const lastDigit = num % 10;
    const suffix =
      num > 10 && num < 20
        ? suffixes[0]
        : lastDigit <= 3
        ? suffixes[lastDigit]
        : suffixes[0];
    return num + suffix;
  }

  function ImageItem({ imageUrl }) {
    const [isValid, setIsValid] = useState(true);

    const handleImageError = () => {
      setIsValid(false);
    };

    return (
      <div>
        {isValid ? (
          <Card.Img
            style={{
              objectFit: "cover",
              display: "block",
              flex: 1,
              border: "none",
              aspectRatio: 16 / 9,
              maxHeight: "55vh",
            }}
            onError={handleImageError}
            variant="top"
            src={imageUrl}
          />
        ) : (
          <Card.Img variant="top" src={NoImage} />
        )}
      </div>
    );
  }

  const checkTimeDiff = (targetDate) => {
    const currentTime = new Date();
    const targetDateValue = new Date(targetDate);
    const timeDifference = targetDateValue - currentTime;

    if (timeDifference > 0 && timeDifference <= 24 * 60 * 60 * 1000) {
      // The target date is within the next 24 hours
      const timeDifferenceInMilliseconds = targetDateValue - currentTime;
      const hours = Math.floor(timeDifferenceInMilliseconds / 3600000); // 1 hour = 3600000 milliseconds
      const minutes = Math.floor(
        (timeDifferenceInMilliseconds % 3600000) / 60000
      ); // 1 minute = 60000 milliseconds
      const seconds = Math.floor((timeDifferenceInMilliseconds % 60000) / 1000); // 1 second = 1000 milliseconds
      return `${hours}h ${minutes}m ${seconds}s`;
    } else if (timeDifference < 0) {
      // The target date is in the past
      return "Live";
    } else {
      return "blank";
    }
  };
  return (
    <div className="page-wrapper-home">
      <div className="main-body-content">
        <div id="sidebar" className="home-header-sideNav">
          <SideBar
            selectOptionIndex={selectedOptionIndex}
            onChangeOption={(index) => setSelectedOptionIndex(index)}
            createEventClicked={() => navigate("/CreateEvent")}
            // onGoToEventClicked={(eventId) =>
            //   navigate(`/EventDetails/${eventId}`, {
            //     state: {
            //       eventId: eventId,
            //     },
            //   })
            // }
            onGoToEventClicked={(eventId, item) => {

              navigate(`/EventDetails/${item?.draftEventId ? item.draftEventId : eventId}`, {
                state: {
                  eventId: eventId,
                  nonActiveEventDetails: item
                },
              });
              
            }}
          />
        </div>

        <div className="rht-body-inner-content">
          <div id="main-header" className="mainTop-header-bar">
            <Header
              name={`${userInfo?.firstName ? userInfo?.firstName : ""} ${
                userInfo?.lastName ? userInfo?.lastName : ""
              }`}
              email={userInfo?.emailAddress ? userInfo?.emailAddress : ""}
              signOutOnClick={() => {}}
              headingText={
                selectedOptionIndex === 2 ? "Settings" : "Your events"
              }
            />
          </div>

          <div className="home-content-div">
            {selectedOptionIndex === 0 ? (
              <div
                className="homeContent-inner-btns"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: 24,
                }}
              >
                <div className="home-info-row-div homeTob-btns-tool">
                  <div className="home-scroll-container homeTob-btns-tool-lft">
                    {assetOptions.map((items, ind) => {
                      return (
                        <div key={ind} style={{ marginRight: 8 }}>
                          <div class="tag-div">
                            <Tags
                              paddingVertical={8}
                              label={
                                items.name +
                                (items?.length ? " (" + items.length + ")" : "")
                              }
                              SourceIcon={
                                items.name === "Music"
                                  ? Music
                                  : items.name === "Sports"
                                  ? Sport
                                  : items.name === "Crypto"
                                  ? CryptoFilled
                                  : null
                              }
                              selected={
                                ind == selectedSegmentIndexAsset ? true : false
                              }
                              onChangeSelection={() =>
                                segmentBtnPressedAsset(ind, items.name)
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="home-scroll-container homeTob-btns-tool-rht">
                    {eventOptions.map((items, ind) => {
                      return (
                        <div key={ind} style={{ marginRight: 8 }}>
                          <div class="tag-div">
                            <Tags
                              paddingVertical={8}
                              label={items.name}
                              selected={
                                ind == selectedSegmentIndexEvent ? true : false
                              }
                              onChangeSelection={() =>
                                segmentBtnPressedEvent(ind)
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div>
                  {isLoading ? (
                    <div
                      style={{
                        display: "grid",
                        placeItems: "center",
                        height: "50vh",
                      }}
                      className="d-flex justify-content-center"
                    >
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : eventList.length === 0 ? (
                    <div style={{ marginTop: 86, paddingBottom: 280 }}>
                      <NoData
                        title={
                          selectedSegmentIndexEvent === 0
                            ? "No events found"
                            : selectedSegmentIndexEvent === 1
                            ? "No expired events"
                            : "No draft events"
                        }
                        description={
                          selectedSegmentIndexEvent === 0
                            ? "You have no upcoming events. Below, you can create an event that will appear in your dashboard."
                            : selectedSegmentIndexEvent === 1
                            ? "You have no expired events. You can find events in this section of the dashboard once they have finished."
                            : "You have no draft events. You can find events in this section of the dashboard once they have finished."
                        }
                        onBtnClicked={() => navigate("/CreateEvent")}
                      />
                    </div>
                  ) : (
                    <div className="home-card-container">
                      {eventList.map((items, ind) => {
                        return (
                          <div class="card-div">
                            <Card
                              onClick={() =>
                                navigate(`/EventDetails/${items?.draftEventId ? items.draftEventId : items.eventId}`, {
                                  state: {
                                    eventId: items.eventId,
                                    nonActiveEventDetails: items
                                  },
                                })
                              }
                              className="card-block"
                              key={ind}
                              style={{
                                display: "flex",
                                flex: 1 / 3,
                                flexDirection: "column",
                                marginLeft: ind % 3 === 0 ? 0 : 20,
                                cursor: "pointer",
                              }}
                            >
                              <div
                                style={{
                                  height: "50%",
                                  width: "100%",
                                  overflow: "hidden",
                                  borderRadius: 8,
                                }}
                              >
                                <ImageItem
                                  key={ind}
                                  imageUrl={
                                    process.env.REACT_APP_API_URL +
                                    items?.imageUri
                                  }
                                />
                              </div>
                              <div
                                class="inner-tag"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                  position: "absolute",
                                  padding: 16,
                                }}
                              >
                                {((checkTimeDiff(items?.startDate) !==
                                  "blank" &&
                                  selectedSegmentIndexEvent !== 1) ||
                                  selectedSegmentIndexEvent === 2) && (
                                  <Tags
                                    notBorder={true}
                                    backgroundColor={
                                      selectedSegmentIndexEvent === 2
                                        ? "var(--red)" 
                                        : selectedSegmentIndexEvent === 0 && checkTimeDiff(items?.startDate) !==
                                        "Live" ? "var(--midBlue)" : "var(--contentError)"
                                    }
                                    className="details-tag-live"
                                    style={{ top: -1, marginLeft: -1 }}
                                    borderRadius={8}
                                    disabled={
                                      selectedSegmentIndexEvent === 1
                                        ? true
                                        : false
                                    }
                                    hasError={
                                      selectedSegmentIndexEvent === 2
                                        ? true
                                        : false
                                    }
                                    paddingVertical={8}
                                    paddingHorizontal={8}
                                    SourceIcon={
                                      selectedSegmentIndexEvent === 1
                                        ? WarningFilled
                                        : selectedSegmentIndexEvent === 2
                                        ? EditFilled
                                        : checkTimeDiff(items?.startDate) ===
                                          "Live"
                                        ? RecordCircle
                                        : ClockFilled
                                    }
                                    label={
                                      selectedSegmentIndexEvent === 1
                                        ? "Expired"
                                        : selectedSegmentIndexEvent === 2
                                        ? "Draft"
                                        : selectedSegmentIndexEvent === 0
                                        ? checkTimeDiff(items?.startDate)
                                        : "Upcoming"
                                    }
                                    selected={true}
                                  />
                                )}
                              </div>

                              <Card.Body
                                style={{
                                  paddingLeft: 0,
                                  paddingRight: 0,
                                  paddingTop: 30,
                                  paddingBottom: 24,
                                }}
                              >
                                <Card.Title>
                                  <Text
                                    style={{
                                      display: "-webkit-box",
                                      WebkitBoxOrient: "vertical", // Use camelCase for vendor-prefixed properties
                                      WebkitLineClamp: 2, // Use camelCase for vendor-prefixed properties
                                      overflow: "hidden",
                                    }}
                                    height={56}
                                    variant={"b500"}
                                  >
                                    {items?.name}
                                  </Text>
                                </Card.Title>

                                <Card.Text>
                                  <div
                                    style={{ marginTop: 16 }}
                                    className="generic-common-column-div eventItem-description-box"
                                  >
                                    <div className="generic-common-row-div eventItem-description-top">
                                      {items?.eventCategoryNames && items?.eventCategoryNames?.length > 0 ? <div
                                        className="listingTag"
                                        style={{
                                          display: "flex",
                                        }}
                                      >
                                        <ButtonPill
                                          type={
                                            selectedSegmentIndexEvent === 1
                                              ? "expired"
                                              : "generic"
                                          }
                                          label={items?.eventCategoryNames ? items?.eventCategoryNames[0] : ''}
                                        />
                                      </div> : null}
                                      {items?.startDate && !items?.startDate?.includes('NaN-NaN') ?  <div
                                        className="listingTag"
                                        style={{
                                          display: "flex",
                                          marginLeft: 8,
                                        }}
                                      >
                                        <ButtonPill
                                          type={
                                            selectedSegmentIndexEvent === 1
                                              ? "expired"
                                              : "generic"
                                          }
                                          label={
                                            getOrdinalSuffix(
                                              new Date(
                                                items?.startDate
                                              ).getDate()
                                            ) +
                                            " " +
                                            months[
                                              new Date(
                                                items?.startDate
                                              ).getMonth()
                                            ] +
                                            " " +
                                            new Date(
                                              items?.startDate
                                            ).getFullYear()
                                          }
                                        />
                                      </div> : null}
                                    </div>
                                    {items?.venue?.addressAsString && items?.venue?.address?.city ? <div className="eventItem-description-bottom">
                                      <div
                                        className="listingTag"
                                        style={{
                                          display: "flex",
                                          marginTop: 8,
                                        }}
                                      >
                                        <ButtonPill
                                          type={
                                            selectedSegmentIndexEvent === 1
                                              ? "expired"
                                              : "generic"
                                          }
                                          label={`${items?.venue?.addressAsString}, ${items?.venue?.address?.city}`}
                                        />
                                      </div>
                                    </div> : null}
                                  </div>
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            ) : selectedOptionIndex === 1 ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingBottom: 280,
                  paddingLeft: 24,
                }}
              >
                {/* <Text marginBottom={16} marginTop={64} variant={"m500"}>
                  What do you need help with?
                </Text> */}

                <div className="home-scroll-container">
                  {HelpOptions.map((items, ind) => {
                    return (
                      <div key={ind} style={{ marginRight: 12 }}>
                        <Tags
                          paddingVertical={8}
                          label={items.name}
                          selected={
                            ind == selectedSegmentIndexHelp ? true : false
                          }
                          onChangeSelection={() => segmentBtnPressedHelp(ind)}
                        />
                      </div>
                    );
                  })}
                </div>
                <div
                  class="allla"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 24,
                  }}
                >
                  <div
                    style={{ marginRight: 24 }}
                    className="home-accordion-container"
                  >
                    {selectedSegmentIndexHelp === 0 &&
                      EventCreationAccordion1.map((itm, ind) => {
                        return (
                          <div key={ind} style={{ marginTop: 16 }}>
                            <Accordion
                              isSegmentChanged={selectedSegmentIndexHelp}
                              index={ind}
                              heading={itm.heading}
                              label={itm.label}
                            />
                          </div>
                        );
                      })}
                    {selectedSegmentIndexHelp === 1 &&
                      ManagingEventAccordion1.map((itm, ind) => {
                        return (
                          <div key={ind} style={{ marginTop: 16 }}>
                            <Accordion
                              isSegmentChanged={selectedSegmentIndexHelp}
                              index={ind}
                              heading={itm.heading}
                              label={itm.label}
                            />
                          </div>
                        );
                      })}

                    {selectedSegmentIndexHelp === 2 &&
                      Marketing1.map((itm, ind) => {
                        return (
                          <div key={ind} style={{ marginTop: 16 }}>
                            <Accordion
                              isSegmentChanged={selectedSegmentIndexHelp}
                              index={ind}
                              heading={itm.heading}
                              label={itm.label}
                            />
                          </div>
                        );
                      })}

{selectedSegmentIndexHelp === 3 &&
                      TicketGuard1.map((itm, ind) => {
                        return (
                          <div key={ind} style={{ marginTop: 16 }}>
                            <Accordion
                              isSegmentChanged={selectedSegmentIndexHelp}
                              index={ind}
                              heading={itm.heading}
                              label={itm.label}
                            />
                          </div>
                        );
                      })}

{selectedSegmentIndexHelp === 4 &&
                      Billing1.map((itm, ind) => {
                        return (
                          <div key={ind} style={{ marginTop: 16 }}>
                            <Accordion
                              isSegmentChanged={selectedSegmentIndexHelp}
                              index={ind}
                              heading={itm.heading}
                              label={itm.label}
                            />
                          </div>
                        );
                      })}

{selectedSegmentIndexHelp === 5 &&
                      VenueApp1.map((itm, ind) => {
                        return (
                          <div key={ind} style={{ marginTop: 16 }}>
                            <Accordion
                              isSegmentChanged={selectedSegmentIndexHelp}
                              index={ind}
                              heading={itm.heading}
                              label={itm.label}
                            />
                          </div>
                        );
                      })}
                  </div>
                  <div className="home-accordion-container">
                    {selectedSegmentIndexHelp === 0 &&
                      EventCreationAccordion2.map((itm, ind) => {
                        return (
                          <div key={ind} style={{ marginTop: 16 }}>
                            <Accordion
                              isSegmentChanged={selectedSegmentIndexHelp}
                              index={ind}
                              heading={itm.heading}
                              label={itm.label}
                            />
                          </div>
                        );
                      })}
                    {selectedSegmentIndexHelp === 1 &&
                      ManagingEventAccordion2.map((itm, ind) => {
                        return (
                          <div key={ind} style={{ marginTop: 16 }}>
                            <Accordion
                              isSegmentChanged={selectedSegmentIndexHelp}
                              index={ind}
                              heading={itm.heading}
                              label={itm.label}
                            />
                          </div>
                        );
                      })}

{selectedSegmentIndexHelp === 2 &&
                      Marketing2.map((itm, ind) => {
                        return (
                          <div key={ind} style={{ marginTop: 16 }}>
                            <Accordion
                              isSegmentChanged={selectedSegmentIndexHelp}
                              index={ind}
                              heading={itm.heading}
                              label={itm.label}
                            />
                          </div>
                        );
                      })}

{selectedSegmentIndexHelp === 3 &&
                      TicketGuard2.map((itm, ind) => {
                        return (
                          <div key={ind} style={{ marginTop: 16 }}>
                            <Accordion
                              isSegmentChanged={selectedSegmentIndexHelp}
                              index={ind}
                              heading={itm.heading}
                              label={itm.label}
                            />
                          </div>
                        );
                      })}

{selectedSegmentIndexHelp === 4 &&
                      Billing2.map((itm, ind) => {
                        return (
                          <div key={ind} style={{ marginTop: 16 }}>
                            <Accordion
                              isSegmentChanged={selectedSegmentIndexHelp}
                              index={ind}
                              heading={itm.heading}
                              label={itm.label}
                            />
                          </div>
                        );
                      })}

{selectedSegmentIndexHelp === 5 &&
                      VenueApp2.map((itm, ind) => {
                        return (
                          <div key={ind} style={{ marginTop: 16 }}>
                            <Accordion
                              isSegmentChanged={selectedSegmentIndexHelp}
                              index={ind}
                              heading={itm.heading}
                              label={itm.label}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div style={{ marginLeft: "-380" }}>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
