import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";
import Slider from "react-slick";
import "./EventStatistics.css";
import ButtonPrimary from "../../Common/Buttons/ButtonPrimary/ButtonPrimary";
import ButtonPill from "../../Common/Buttons/ButtonPill";
import Text from "../../Common/Text";
import { ReactComponent as TicketFilled } from "../../../assets/svgs/TicketFilled.svg";
import { ReactComponent as CheckListFilled } from "../../../assets/svgs/CheckListFilled.svg";
import { ReactComponent as CoinFilled } from "../../../assets/svgs/CoinFilled.svg";
import DropdownInput from "../../Common/DropdownInput/DropdownInput";

export default function EventStatistics({
  type,
  title,
  statType,
  eventStatisticsObj,
  data1,
  data2,
  colors1,
  colors2,
  onAttendeeListClicked,
  ticketTypesList,
  selectedTicketType,
  setSelectedTicketType,
  ticketSellingEnabled,
  onPuaseResumeTicketSales,
}) {
  const [chartDimensions, setChartDimensions] = useState({
    width: "270px",
    height: "270px",
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  const dynamicHeight = "123px";
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setChartDimensions({ width: "100%", height: "250px" });
      } else {
        setChartDimensions({ width: "270px", height: "270px" });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isData1ElementExistsAndIsNull =
    Array.isArray(data1) &&
    data1.length > 1 &&
    Array.isArray(data1[1]) &&
    data1[1].length > 1 &&
    data1[1][1] === null;

  return (
    <div
      style={{ marginBottom: statType === "ticket" ? 0 : 0 }}
      className="event-details-pie-chart-parent-content"
    >
      <div className="topTitleDropdownCont">
        <div className="TitleCont generic-common-row-div saleStatics-tittle-block">
          <div className="saleStatics-text">
            <Text marginRight={12} variant={"m500"}>
              {title}
            </Text>
          </div>
          <div className="ticket-sales-batch">
            {statType === "sales" && !ticketSellingEnabled && (
              <ButtonPill
                type={"partiallycompleted"}
                varient={"small"}
                label={"Ticket sales paused"}
              />
            )}
          </div>
          {statType === "sales" && (
            <div className="DropdownCont">
              <DropdownInput
                eventCategory={selectedTicketType}
                data={ticketTypesList}
                onSelectedItem={(value) => {
                  setSelectedTicketType(value);
                }}
                editable={true}
              />
            </div>
          )}
        </div>
      </div>
      <div
        className="live-event-statistics-parent-view eventDetails-slick-container"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Slider {...settings}>
          <div
            className="live-event-statistics-left-parent-container"
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1 / 2,
            }}
          >
            <div className="live-event-statistics-inner-chart-container EventChartCont">
              <Chart
                chartType="PieChart"
                // width={270}
                // height={270}
                width={chartDimensions.width}
                height={chartDimensions.height}
                data={data1}
                options={{
                  title: "",
                  pieHole: 0.82,
                  is3D: false,
                  legend: "none",
                  pieSliceText: "none",
                  chartArea: { left: 16, right: 40, top: 0, bottom: 0 },
                  colors: colors1,
                }}
              />
              <div className="event-details-centered-div-into-pie-chart ChartDetailsCont">
                {statType === "ticket" ? (
                  <TicketFilled
                    style={{
                      marginTop: isData1ElementExistsAndIsNull
                        ? parseInt(data1[1][1]) < 0 || parseInt(data1[2][1]) < 0
                          ? 32
                          : 0
                        : 0,
                    }}
                  />
                ) : (
                  <CoinFilled
                    style={{
                      marginTop: isData1ElementExistsAndIsNull
                        ? parseInt(data1[1][1]) < 0 || parseInt(data1[2][1]) < 0
                          ? 32
                          : 0
                        : 0,
                    }}
                  />
                )}
                <Text marginTop={8} variant={"b40018"}>
                  {statType === "ticket"
                    ? "Ticket tracker"
                    : selectedTicketType === "All tickets" ||
                      ticketTypesList.find(
                        (ticketType) => ticketType.name === selectedTicketType
                      )?.isAddOn === false
                    ? "Ticket sales"
                    : selectedTicketType === "All add-ons" ||
                      ticketTypesList.find(
                        (ticketType) => ticketType.name === selectedTicketType
                      )?.isAddOn === true
                    ? "Add-on sales"
                    : "All sales"}
                </Text>
              </div>
            </div>
            <div
              className="live-event-statistics-inner-label-container"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: 200,
              }}
            >
              <div className="statistics-inner-itemBox">
                <Text variant={"b500"}>
                  {statType === "ticket"
                    ? (
                        parseInt(eventStatisticsObj?.toScan) +
                        parseInt(eventStatisticsObj?.scanned)
                      )?.toLocaleString()
                    : parseInt(
                        eventStatisticsObj?.tickets || "0"
                      )?.toLocaleString()}
                  {/* {parseInt(
                    eventStatisticsObj?.tickets || '0'
                  )?.toLocaleString()} */}
                </Text>
                <div className="statistics-smText">
                  {" "}
                  <Text variant={"m300"}>
                    {statType === "ticket" ||
                    selectedTicketType === "All tickets" ||
                    ticketTypesList.find(
                      (ticketType) => ticketType.name === selectedTicketType
                    )?.isAddOn === false
                      ? "total tickets"
                      : selectedTicketType === "All add-ons" ||
                        ticketTypesList.find(
                          (ticketType) => ticketType.name === selectedTicketType
                        )?.isAddOn === true
                      ? "total add-ons"
                      : "total items"}
                  </Text>
                </div>
              </div>

              <div className="statistics-inner-itemBox">
                <div
                  style={{
                    marginBottom: 8,
                    marginTop: 24,
                    height: 4,
                    width: "100%",
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    background:
                      "linear-gradient(272deg, #46AFF5 0%, #4688F5 100%)",
                  }}
                />
                <Text variant={"b500"}>
                  {statType === "ticket"
                    ? eventStatisticsObj?.scanned
                      ? parseInt(eventStatisticsObj?.scanned)?.toLocaleString()
                      : 0
                    : eventStatisticsObj?.ticketsSold !== undefined
                    ? parseInt(
                        eventStatisticsObj?.ticketsSold
                      )?.toLocaleString()
                    : 0}
                </Text>
                <div className="statistics-smText">
                  <Text variant={"m300"}>
                    {statType === "ticket"
                      ? "scanned"
                      : selectedTicketType === "All tickets" ||
                        ticketTypesList.find(
                          (ticketType) => ticketType.name === selectedTicketType
                        )?.isAddOn === false
                      ? "tickets sold"
                      : selectedTicketType === "All add-ons" ||
                        ticketTypesList.find(
                          (ticketType) => ticketType.name === selectedTicketType
                        )?.isAddOn === true
                      ? "add-ons sold"
                      : "items sold"}
                  </Text>
                </div>
              </div>

              <div className="statistics-inner-itemBox">
                <div
                  style={{
                    marginBottom: 8,
                    marginTop: 24,
                    height: 4,
                    width: "100%",
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    backgroundColor: getComputedStyle(
                      document.documentElement
                    ).getPropertyValue("--midnightBlue"),
                  }}
                />
                <Text variant={"b500"}>
                  {statType === "ticket"
                    ? eventStatisticsObj?.toScan
                      ? parseInt(eventStatisticsObj?.toScan)?.toLocaleString()
                      : "0"
                    : eventStatisticsObj?.ticketsSold !== undefined
                    ? (
                        parseInt(eventStatisticsObj?.tickets || "0") -
                        parseInt(eventStatisticsObj?.ticketsSold || "0")
                      )?.toLocaleString()
                    : 1}
                </Text>
                <div className="statistics-smText">
                  <Text width={300} variant={"m300"}>
                    {statType === "ticket"
                      ? "still to scan"
                      : selectedTicketType === "All tickets" ||
                        ticketTypesList.find(
                          (ticketType) => ticketType.name === selectedTicketType
                        )?.isAddOn === false
                      ? "tickets still available"
                      : selectedTicketType === "All add-ons" ||
                        ticketTypesList.find(
                          (ticketType) => ticketType.name === selectedTicketType
                        )?.isAddOn === true
                      ? "add-ons still available"
                      : "items still available"}
                  </Text>
                </div>
              </div>
            </div>
          </div>

          {statType === "sales" ? (
            <div
              className="SalesInfoContDiv"
              style={{
                display: "flex",
                flexDirection: "column",
                flex: 1 / 2,
                marginLeft: 24,
                alignItems: "flex-start",
                justifyContent: "center",
              }}
            >
              <div className="generic-common-row-div SalesInfoCont TopCont">
                {eventStatisticsObj?.netSalesValue !== undefined &&
                  eventStatisticsObj?.netSalesValue !== null && (
                    <div className="sales-info-inner-parent-div">
                      <Text
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--midBlue")}
                        variant={"m200"}
                      >
                        {"GROSS SALES"}
                      </Text>

                      <Text marginTop={8} variant={"b500"}>
                        {`${eventStatisticsObj?.netSalesValue}`}
                      </Text>
                    </div>
                  )}

                {eventStatisticsObj?.averageOrderValue !== undefined &&
                  eventStatisticsObj?.averageOrderValue !== null && (
                    <div
                      // style={{ marginLeft: 24 }}
                      className="sales-info-inner-parent-div"
                    >
                      <Text
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--midBlue")}
                        variant={"m200"}
                      >
                        {"AVERAGE ORDER VALUE"}
                      </Text>

                      <Text marginTop={8} variant={"b500"}>
                        {`${eventStatisticsObj?.averageOrderValue}`}
                      </Text>
                    </div>
                  )}
              </div>

              <div
                style={{ marginTop: 24 }}
                className="generic-common-row-div SalesInfoCont BottomCont"
              >
                {eventStatisticsObj?.ticketsSoldLastDay !== undefined &&
                  eventStatisticsObj?.ticketsSoldLastDayValue !== undefined &&
                  eventStatisticsObj?.ticketsSoldLastDay !== null &&
                  eventStatisticsObj?.ticketsSoldLastDayValue !== null && (
                    <div className="sales-info-inner-parent-div">
                      <Text
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--midBlue")}
                        variant={"m200"}
                      >
                        {"TODAY (SALES)"}
                      </Text>

                      <Text marginTop={8} variant={"b500"}>
                        {`${eventStatisticsObj?.ticketsSoldLastDay} SOLD | ${eventStatisticsObj?.ticketsSoldLastDayValue}`}
                      </Text>
                    </div>
                  )}

                {eventStatisticsObj?.ticketsSoldLastMonth !== undefined &&
                  eventStatisticsObj?.ticketsSoldLastMonthValue !== undefined &&
                  eventStatisticsObj?.ticketsSoldLastMonth !== null &&
                  eventStatisticsObj?.ticketsSoldLastMonthValue !== null && (
                    <div
                      // style={{ marginLeft: 24 }}
                      className="sales-info-inner-parent-div"
                    >
                      <Text
                        color={getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--midBlue")}
                        variant={"m200"}
                      >
                        {"THIS MONTH (SALES)"}
                      </Text>

                      <Text marginTop={8} variant={"b500"}>
                        {`${eventStatisticsObj?.ticketsSoldLastMonth} SOLD | ${eventStatisticsObj?.ticketsSoldLastMonthValue}`}
                      </Text>
                    </div>
                  )}
              </div>
            </div>
          ) : (
            <div
              className="live-event-statistics-right-parent-container"
              style={{
                display: "flex",
                flexDirection: "row",
                flex: 1 / 2,
                marginLeft: 24,
                justifyContent: "flex-end",
              }}
            >
              <div className="live-event-statistics-inner-chart-container EventChartCont">
                <Chart
                  chartType="PieChart"
                  // width={270}
                  // height={270}
                  width={chartDimensions.width}
                  height={chartDimensions.height}
                  data={data2}
                  options={{
                    title: "",
                    pieHole: 0.82,
                    is3D: false,
                    legend: "none",
                    pieSliceText: "none",
                    chartArea: { left: 16, right: 40, top: 0, bottom: 0 },
                    colors: colors2,
                  }}
                />
                <div className="event-details-centered-div-into-pie-chart-right ChartDetailsCont">
                  <CheckListFilled />
                  <Text marginTop={8} variant={"b40018"}>
                    {"Attendee status"}
                  </Text>
                </div>
              </div>
              <div
                className="live-event-statistics-inner-label-container"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: 127,
                }}
              >
                <div className="statistics-inner-itemBox">
                  <div
                    style={{
                      marginBottom: 8,
                      height: 4,
                      width: "100%",
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                      backgroundColor: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--green"),
                    }}
                  />
                  <Text variant={"b500"}>
                    {eventStatisticsObj?.checkedIn !== undefined
                      ? eventStatisticsObj?.checkedIn
                      : "0"}
                  </Text>
                  <div className="statistics-smText">
                    <Text variant={"m300"}>
                      {type === "expired" ? "total approved" : "approved"}
                    </Text>
                  </div>
                </div>

                <div className="statistics-inner-itemBox">
                  <div
                    style={{
                      marginBottom: 8,
                      marginTop: 24,
                      height: 4,
                      width: "100%",
                      borderTopLeftRadius: 4,
                      borderTopRightRadius: 4,
                      backgroundColor: getComputedStyle(
                        document.documentElement
                      ).getPropertyValue("--red"),
                    }}
                  />
                  <Text variant={"b500"}>
                    {eventStatisticsObj?.rejected !== undefined
                      ? eventStatisticsObj?.rejected
                      : "0"}
                  </Text>
                  <div className="statistics-smText">
                    <Text variant={"m300"}>
                      {type === "expired" ? "total rejected" : "rejected"}
                    </Text>
                  </div>
                </div>

                {type !== "expired" && (
                  <div
                    className="statistics-inner-itemBox"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div
                      style={{
                        marginBottom: 8,
                        marginTop: 24,
                        height: 4,
                        width: "100%",
                        borderTopLeftRadius: 4,
                        borderTopRightRadius: 4,
                        backgroundColor: getComputedStyle(
                          document.documentElement
                        ).getPropertyValue("--midBlue"),
                      }}
                    />
                    <Text variant={"b500"}>
                      {eventStatisticsObj?.checkedOut !== undefined
                        ? eventStatisticsObj?.checkedOut
                        : "0"}
                    </Text>

                    <div className="statistics-smText">
                      <Text variant={"m300"}>{"checked out"}</Text>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </Slider>
      </div>

      <div
        style={{ marginTop: 48 }}
        className="generic-common-row-centerd-div event-details-ticletSales-btns"
      >
        <ButtonPrimary
          style={{ marginRight: "24px" }}
          width={328}
          label={
            statType === "ticket"
              ? "View attendee entry list"
              : "View order history"
          }
          onPress={() => onAttendeeListClicked()}
        />
        {statType === "sales" && (
          <ButtonPrimary
            isClickingEffectEnabled={true}
            isSecondary={true}
            width={328}
            label={
              ticketSellingEnabled === true
                ? "Pause ticket sales"
                : "Resume ticket sales"
            }
            onPress={() => onPuaseResumeTicketSales()}
          />
        )}
      </div>
    </div>
  );
}
