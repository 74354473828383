import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as Icon } from "../../assets/svgs/Icon.svg";
import { ReactComponent as MobileLogo } from "../../assets/svgs/mobile-logo.svg";
import { ReactComponent as MobileMenuClose } from "../../assets/svgs/mobile-menu-close.svg";
import { ReactComponent as IconMenu } from "../../assets/svgs/menu-icon.svg";
import { ReactComponent as HomeIcon } from "../../assets/svgs/HomeNew.svg";
import { ReactComponent as HelpFilled } from "../../assets/svgs/HelpNew.svg";
import { ReactComponent as GearIcon } from "../../assets/svgs/CogFilled.svg";
import { ReactComponent as Logout } from "../../assets/svgs/Logout.svg";
import { ReactComponent as UserFilled } from "../../assets/svgs/UserFilled.svg";
import NoImage from "../../assets/png/NoImage.png";
import HoverBGButton from "../Common/Buttons/HoverBGButton/HoverBGButton";
import Text from "../Common/Text";
import Tags from "../Common/Tags/Tags";
import { EVENT_DATA, SELECTED_EVENT_DATA } from "../../actions";
import {
  getEventList,
  logOutUser,
  getInactiveDraftEventList,
  getDraftEventExist,
} from "../../services/api";
import "./SideBar.css";

const Sidebar = ({
  selectOptionIndex,
  onChangeOption,
  createEventClicked,
  onGoToEventClicked,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const userInfo = useSelector((state) => state.userData?.userData);
  const eventList = useSelector((state) => state.eventData?.eventData);
  const selectedEventData = useSelector(
    (state) => state.selectedEventData?.selectedEventData
  );
  const showDraft = useSelector(
    (state) => state.showDraftEvent?.showDraftEvent
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [eventOptions, setEventOptions] = useState([
    { name: "Upcoming", id: 1 },
    { name: "Expired", id: 2 },
    { name: "Draft", id: 3 },
  ]);
  const [isHoveredHome, setIsHoveredHome] = useState(false);
  const [isHoveredSupport, setIsHoveredSupport] = useState(false);
  const [isHoveredSettings, setIsHoveredSettings] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] =
    useState(selectedEventData);
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState(
    parseInt(localStorage.getItem("selectedSegmentIndex")) || 0
  );
  // const [eventList, setEventList] = useState([]);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const scrollRef = useRef([]);

  useEffect(() => {
    scrollRef?.current[selectedEventData - 3]?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
    setSelectedOptionIndex(selectedEventData);
  }, [selectedEventData]);

  useEffect(() => {
    var selectedSegmentIndexLocal = parseInt(
      localStorage.getItem("selectedSegmentIndex")
    );
    setSelectedSegmentIndex(
      selectedSegmentIndexLocal === undefined ||
        selectedSegmentIndexLocal === null ||
        (selectedSegmentIndexLocal !== 1 &&
          selectedSegmentIndexLocal !== 2 &&
          selectedSegmentIndexLocal !== 0)
        ? 0
        : selectedSegmentIndexLocal
    );
  }, []);

  // useEffect(() => {
  //   showDraft
  //     ? setEventOptions([
  //         { name: "Upcoming", id: 1 },
  //         { name: "Expired", id: 2 },
  //         { name: "Draft", id: 3 },
  //       ])
  //     : setEventOptions([
  //         { name: "Upcoming", id: 1 },
  //         { name: "Expired", id: 2 },
  //       ]);
  // }, [showDraft]);

  const logOutRequest = async () => {
    const result = await logOutUser();
    if (result.status) {
      localStorage.clear();
      navigate("/Login");
    }
  };

  const eventData = async (type, isPublished) => {
    const result = await getEventList({
      isPublished: isPublished,
      type: type,
    });
    if (result.status) {
      isPublished
        ? dispatch({ type: EVENT_DATA, payload: result.response })
        : getInactiveDraftEventListRequest(result.response);
    } else {
      isPublished ?  dispatch({ type: EVENT_DATA, payload: [] }) : getInactiveDraftEventListRequest([]);
    }
    setIsLoading(false);
  };

  const getInactiveDraftEventListRequest = async (existingDraftList) => {
    const result = await getInactiveDraftEventList({});
    if (result.status) {
      dispatch({
        type: EVENT_DATA,
        payload: [...existingDraftList, ...result.response],
      });
    } else {
      dispatch({ type: EVENT_DATA, payload: existingDraftList });
    }
    setIsLoading(false);
  };

  const segmentBtnPressed = (index) => {
    if (index != selectedSegmentIndex) {
      setSelectedSegmentIndex(index);
      eventData(
        index === 0 ? "Current" : index === 1 ? "Past" : "All",
        index === 2 ? false : true
      );
      localStorage.setItem("selectedSegmentIndex", index);
    }
  };

  function getOrdinalSuffix(num) {
    const suffixes = ["th", "st", "nd", "rd"];
    const lastDigit = num % 10;
    const suffix =
      num > 10 && num < 20
        ? suffixes[0]
        : lastDigit <= 3
        ? suffixes[lastDigit]
        : suffixes[0];
    return (num || '') + (num ? suffix : '');
  }

  const handleClickMobileMenu = (value) => {
    // Toggle the state to add or remove the class name
    setShowMobileMenu(value);
  };

  function ImageItem({ imageUrl }) {
    const [isValid, setIsValid] = useState(true);

    const handleImageError = () => {
      setIsValid(false);
    };

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {isValid ? (
          <img
            style={{
              height: 48,
              width: 48,
              borderRadius: 24,
              boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.20)",
            }}
            onError={handleImageError}
            src={imageUrl}
          />
        ) : (
          <HoverBGButton
            icon={<UserFilled height={24} width={24} />}
            size={48}
          />
        )}
      </div>
    );
  }

  const confirmLogout = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      logOutRequest();
    } else {
    }
  };

  return (
    <div className="sidebar sidebar-for-mobile">
      <div className="mobile-menu-bar">
        <div
          style={{ cursor: pathname === "/Home" ? null : "pointer" }}
          onClick={() => navigate("/Home")}
          className="mobile-menu-barLogo"
        >
          <MobileLogo width={32} height={32} />
        </div>
        <div
          onClick={() => handleClickMobileMenu(true)}
          className="mobile-menu-icon-humberg"
        >
          <IconMenu width={24} height={24} />
        </div>
      </div>

      <div className={`mobile-menu-content ${showMobileMenu ? "show" : ""}`}>
        <div className="sb-top-dov">
          <div className="mobile-menu-contentTop-bar">
            <div
              style={{
                cursor:
                  pathname === "/Home" && selectOptionIndex !== 1
                    ? null
                    : "pointer",
              }}
              onClick={() => {
                if (selectOptionIndex === 1) {
                  setSelectedOptionIndex(0);
                  onChangeOption(0);
                  dispatch({ type: SELECTED_EVENT_DATA, payload: 0 });
                  setShowMobileMenu(false);
                }
                navigate("/Home");
              }}
              className="mobile-menu-contentTop-lft"
            >
              <Icon width={64} height={64} />
              <Text cursor={"pointer"} variant="b500" marginLeft={16}>
                Box Office
              </Text>
            </div>
            <div
              onClick={() => handleClickMobileMenu(false)}
              className="mobile-menu-contentTop-rht"
            >
              <MobileMenuClose width={22} height={22} />
            </div>
          </div>

          <div
            onMouseEnter={() => setIsHoveredHome(true)}
            onMouseLeave={() => setIsHoveredHome(false)}
            onClick={() => (
              setSelectedOptionIndex(0),
              onChangeOption(0),
              navigate("/Home"),
              setShowMobileMenu(false),
              dispatch({ type: SELECTED_EVENT_DATA, payload: 0 })
            )}
            style={{
              // marginTop: 40,
              // marginTop: "4%",
              paddingTop: 16,
              paddingBottom: 16,
              justifyContent: "space-between",
              // height: 64,
              height: "auto",
              maxHeight: 64,
              backgroundColor: isHoveredHome ? "#F1F2F4" : "var(--white)",
              cursor: "pointer",
            }}
            className="sb-options-div"
          >
            <div
              className="sb-options-div"
              style={{
                justifyContent: "center",
              }}
            >
              <HomeIcon width={32} height={32} />
              <Text cursor={"pointer"} variant="m400" marginLeft={16}>
                Home
              </Text>
            </div>
            {selectedOptionIndex == 0 &&
              pathname != "/CreateEvent" &&
              pathname != "/EventDetails" && (
                <div className="sb-indicator-view" />
              )}
          </div>

          <div className="sb-divider" />
        </div>

        <div className="yourEventCont">
          <div className="yourEventTop">
            <Text variant="m400">Your events</Text>
            <div className="sb-scroll-container">
              {eventOptions.map((items, ind) => {
                return (
                  <div key={ind} style={{ marginRight: 12 }}>
                    <Tags
                      paddingVertical={8}
                      label={items.name}
                      selected={ind === selectedSegmentIndex ? true : false}
                      onChangeSelection={() => segmentBtnPressed(ind)}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="sb-scroll-container-vertical SideBarEventCont">
            {!eventList ? (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "50vh",
                }}
                className="d-flex justify-content-center"
              >
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              eventList?.length > 0 && eventList.map((item, ind) => {
                return (
                  <div
                    ref={(ref) => (scrollRef.current[ind] = ref)}
                    key={ind}
                    onClick={() => {
                      dispatch({ type: SELECTED_EVENT_DATA, payload: ind + 3 });
                      setSelectedOptionIndex(ind + 3);
                      //onChangeOption(ind + 3);
                      onGoToEventClicked(item?.eventId, item);
                    }}
                    className="generic-common-row-div SideBarEventBox"
                    style={{
                      paddingTop: 12,
                      paddingBottom: 12,
                      cursor: "pointer",
                    }}
                  >
                    <div className="EventImgCont">
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <img
                          style={{
                            height: 48,
                            width: 48,
                            borderRadius: 24,
                            boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.20)",
                          }}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = NoImage;
                          }}
                          src={process.env.REACT_APP_API_URL + item?.imageUri}
                        />
                      </div>
                    </div>
                    <div
                      style={{ width: "100%" }}
                      className="generic-common-row-div-space-between"
                    >
                      <div
                        style={{ marginLeft: 16 }}
                        className="generic-common-column-div"
                      >
                        <Text cursor={"pointer"} variant={"m300"}>
                          {item?.name || ""}
                        </Text>
                        <Text
                          marginTop={3}
                          cursor={"pointer"}
                          color={"var(--grey)"}
                          variant={"r200"}
                        >
                          {(getOrdinalSuffix(
                            new Date(item?.startDate).getDate()
                          ) || "") +
                            " " +
                            (months[new Date(item?.startDate).getMonth()] ||
                              "") +
                            " " +
                            (new Date(item?.startDate).getFullYear() || "")}
                        </Text>
                      </div>
                    </div>
                    {/* {selectedOptionIndex === 2 && */}
                    {selectedOptionIndex - 3 == ind && (
                      <div className="sb-indicator-view" />
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>

        <div className="bottom-sideBar-loginProfile-block">
          <div
            onClick={() => (
              dispatch({ type: SELECTED_EVENT_DATA, payload: 1 }),
              setSelectedOptionIndex(1),
              onChangeOption(1),
              setShowMobileMenu(false)
            )}
            style={{
              marginTop: 5,
              justifyContent: "space-between",
              height: 64,
              backgroundColor: isHoveredSupport ? "#F1F2F4" : "var(--white)",
              cursor: "pointer",
            }}
            className="sb-options-div"
            onMouseEnter={() => setIsHoveredSupport(true)}
            onMouseLeave={() => setIsHoveredSupport(false)}
          >
            <div
              className="sb-options-div"
              style={{ justifyContent: "center" }}
            >
              <HelpFilled width={32} height={32} />
              <Text cursor={"pointer"} variant="m400" marginLeft={16}>
                Support
              </Text>
            </div>
            {selectedOptionIndex == 1 &&
              pathname != "/CreateEvent" &&
              pathname != "/EventDetails" && (
                <div className="sb-indicator-view" />
              )}
          </div>
          <div
            onClick={() => {
              // setSelectedOptionIndex(2);
              // onChangeOption(2);
              // setShowMobileMenu(false);
              dispatch({ type: SELECTED_EVENT_DATA, payload: 2 });
              setSelectedOptionIndex(2);
              onChangeOption(2);
              navigate("/Settings");
              setShowMobileMenu(false);
            }}
            style={{
              marginTop: 0,
              justifyContent: "space-between",
              height: 60,
              backgroundColor: isHoveredSettings ? "#F1F2F4" : "var(--white)",
              cursor: "pointer",
            }}
            className="sb-options-div"
            onMouseEnter={() => setIsHoveredSettings(true)}
            onMouseLeave={() => setIsHoveredSettings(false)}
          >
            <div
              className="sb-options-div"
              style={{ justifyContent: "center" }}
            >
              <GearIcon width={32} height={32} />
              <Text variant="m400" marginLeft={16}>
                Settings
              </Text>
            </div>
            {selectedOptionIndex === 2 &&
              pathname != "/CreateEvent" &&
              pathname != "/EventDetails" && (
                <div className="sb-indicator-view" />
              )}
          </div>
          {/* <div style={{ marginBottom: 24 }} className="sb-divider" /> */}
          <div
            className="generic-common-row-div-space-between"
            style={{ gap: 16 }}
          >
            <div className="generic-common-row-div" style={{ gap: 16 }}>
              <ImageItem
                style={{
                  objectFit: "cover",
                  display: "flex",
                  flex: 1,
                }}
                imageUrl={process.env.REACT_APP_API_URL + userInfo?.imageUri}
              />
              <div
                style={{ flex: "1 0 0", gap: 8 }}
                className="generic-common-column-div"
              >
                <Text
                  style={{
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                  variant={"profileNameM300"}
                >{`${userInfo?.firstName || ""} ${
                  userInfo?.lastName || ""
                }`}</Text>
                <Text
                  style={{
                    color: "#767676",
                    display: "-webkit-box",
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    wordWrap: "anywhere",
                  }}
                  variant={"profileEmailM300"}
                >
                  {userInfo?.emailAddress || ""}
                </Text>
              </div>
            </div>
            <div
              onClick={() => confirmLogout()}
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 16,
                cursor: "pointer",
              }}
            >
              <Logout width={24} height={24} />
            </div>
          </div>
        </div>

        {/* <div style={{ marginTop: 24 }} className="sb-divider" /> */}
      </div>
    </div>
  );
};

export default Sidebar;
