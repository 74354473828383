import React, { useEffect, useState } from "react";
import axios from "axios";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import Login from "../src/Pages/Login/Login";
import Home from "../src/Pages/Home/Home";
import "reactjs-popup/dist/index.css";
import NotFound from "../src/Pages/NotFound/NotFound";
import NoNetwork from "../src/Pages/NotFound/NoNetwork";
import BusinessSignUp from "../src/Pages/SignUp/BusinessSignUp";
import VerifyEmail from "../src/Pages/SignUp/VerifyEmail";
import UpdateEmailVerifyEmail from "../src/Pages/SignUp/UpdateEmailVerifyEmail";
import CreateEvent from "../src/Pages/CreateEvent/CreateEvent";
import EventDetails from "../src/Pages/EventDetails/EventDetails";
import AttendeeList from "../src/Pages/AttendeeList/AttendeeList";
import OrderList from "../src/Pages/OrderHistory/OrderList";
import OrderDetails from "../src/Pages/OrderHistory/OrderDetails";
import AttendeeDetails from "../src/Pages/AttendeeList/AttendeeDetails";
import SignUp from "../src/Pages/SignUp/SignUp";
import ForgotPassword from "../src/Pages/ForgotPassword/ForgotPassword";
import CodeSubmit from "../src/Pages/ForgotPassword/CodeSubmit";
import CreatePassword from "../src/Pages/ForgotPassword/CreatePassword";
import Settings from "../src/Pages/Settings/Settings";
import About from "./Pages/CMS/About";
import TicketGuard from "./Pages/CMS/TicketGuard";
import Email from "./Pages/CMS/Email";
import Twitter from "./Pages/CMS/Twitter";
import PasswordUpdated from "../src/Pages/ForgotPassword/PasswordUpdated";
import Banner from "../src/Component/Common/Banner/Banner";
import Loader from "../src/Component/Common/Loader/Loader";
import "./App.css";
import Api from "./utils/Api";
import {
  USER_DATA,
  CURRENCY_DATA,
  COUNTRY_DATA,
  EVENT_CATEGORY_DATA,
  EVENT_DATA,
  SHOW_DRAFT,
  AUTH_TOKEN,
} from "./actions";
import {
  getUserDetails,
  getCurrencyList,
  getCountryList,
  getEventCategories,
  getEventList,
  refreshToken,
  getDraftEventExist,
  logOutUser,
  getInactiveDraftEventList
} from "./services/api";
import loaderGif from "../src/assets/loader-tx2.gif"; 
var token = localStorage.getItem("authToken");
// test commits
function App() {
  const { pathname } = useLocation();
  const stripePromise = loadStripe('pk_test_51OhsIwArT4t9KNCkQaIiYB4DIfEyYOWspZB7tMw0Xtl6tQiwCH6jqLSpm0kkTmDLUWe6wULvd9Jt3g0bQiOIfHQw00DgunrPDc');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bannerContent = useSelector(
    (state) => state.bannerContent?.bannerContent
  );
  const [isLoaded, setIsLoaded] = useState(false);
  const [initialRouteName, setInitialRouteName] = useState("");

  useEffect(() => {
    const delay = 4000;
    const timeoutId = setTimeout(() => {
      userData();
      eventData();
      getCurrencyListRequest();
      getCountryListRequest();
      getEventCategoriesRequest();
      setIsLoaded(true);
    }, delay);
    return () => clearTimeout(timeoutId);
  }, []);

  const userData = async () => {
    const result = await getUserDetails();
    if (result.status) {
      if (new Date(result.response?.jwtTokenExpiryUtc) < new Date()) {
        logOutRequest();
      } else {
        dispatch({ type: USER_DATA, payload: result.response });
        setInitialRouteName(result.response?.businesses?.length > 0 ? "Home" : "BusinessSignUp");
      }
    } else {
      if (result?.response?.status === 401) {
        const res = await refreshToken();
        if (res?.status) {
          localStorage.setItem("authToken", JSON.stringify(res.response));
          dispatch({ type: AUTH_TOKEN, payload: res.response });
          Api.defaultHeader({
            authorization: `Bearer ${res.response}`,
          });
          await userData();
        } else {
          localStorage.clear();
          token = undefined;   
          console.log("pathname at app.js", pathname);
          setInitialRouteName( pathname === '/SignUp' ? "SignUp" : "Login");
          navigate("/", { replace: true });
        }
      } else if (result?.status === false) {
        // window.alert(
        //   "Network Error: Failed to fetch. Please check your internet connection and try again"
        // );
        setInitialRouteName("NoNetwork");
         navigate("/", { replace: true });

      }
    }
  };

  const getCurrencyListRequest = async () => {
    const result = await getCurrencyList({});
    if (result.status) {
      dispatch({ type: CURRENCY_DATA, payload: result.response });
    }
  };

  const getCountryListRequest = async () => {
    const result = await getCountryList({});
    if (result.status) {
      dispatch({ type: COUNTRY_DATA, payload: result.response });
    }
  };

  const getEventCategoriesRequest = async () => {
    const result = await getEventCategories({});
    if (result.status) {
      dispatch({ type: EVENT_CATEGORY_DATA, payload: result.response });
    }
  };

  const eventData = async (type) => {
    const result = await getEventList({
      isPublished:
        parseInt(localStorage.getItem("selectedSegmentIndex")) === 2
          ? false
          : true,
      type:
        localStorage.getItem("selectedSegmentIndex") === null ||
        parseInt(localStorage.getItem("selectedSegmentIndex")) === 0
          ? "Current"
          : parseInt(localStorage.getItem("selectedSegmentIndex")) === 1
          ? "Past"
          : "All",
    });
    if (result.status) {
      // dispatch({ type: EVENT_DATA, payload: result.response });
      parseInt(localStorage.getItem("selectedSegmentIndex")) !== 2
        ? dispatch({ type: EVENT_DATA, payload: result.response })
        : getInactiveDraftEventListRequest(result.response);
      checkForDraft();
    } else {
      checkForDraft();
      dispatch({ type: EVENT_DATA, payload: [] });
    }
  };

  const getInactiveDraftEventListRequest = async (existingDraftList) => {
    const result = await getInactiveDraftEventList({});
    if (result.status) {
      dispatch({
        type: EVENT_DATA,
        payload: [...existingDraftList, ...result.response],
      });
    } else {
      dispatch({ type: EVENT_DATA, payload: existingDraftList });
    }
  };

  const checkForDraft = async () => {
    const result = await getDraftEventExist({});
    dispatch({
      type: SHOW_DRAFT,
      payload: result?.response?.count > 0 ? true : false,
    });
  };

  // const checkForDraft = async () => {
  //   const result = await getEventList({
  //     isPublished: false,
  //     category: "",
  //     type: "All",
  //   });
  //   userData();
  //   dispatch({
  //     type: SHOW_DRAFT,
  //     payload: result?.response?.length > 0 ? true : false,
  //   });
  // };

  const logOutRequest = async () => {
    const result = await logOutUser();
    if (result.status) {
      localStorage.clear();
      token = undefined;
      setInitialRouteName("Login");
      navigate("/", { replace: true });
      window.alert("The session has expired. Please log in again.");
    }
  };
  return (
    <>
      {initialRouteName === "" ? (
        <div className="page-wrapper-splash">
          <div
            className="d-flex justify-content-center loader-wrapper"
          >
            {/* <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div> */}
            <img
      src={loaderGif} // Use your GIF path here
      alt="Loading..."
      style={{ width: "120px", height: "auto" }} // You can customize the size
    />
          </div>
        </div>
      ) : (
        <Elements stripe={stripePromise} options={{}}>
        <Routes>
          <Route
            path="/"
            element={initialRouteName === "Home" ? <Home /> : initialRouteName === "NoNetwork" ? <NoNetwork /> : initialRouteName === "SignUp" ? < SignUp /> : <Login />}
          />
          <Route path="*" element={<NotFound />} />
          <Route path="Login" element={<Login />} />
          <Route path="NoNetwork" element={<NoNetwork />} />
          <Route path="SignUp" element={<SignUp />} />
          <Route path="ForgotPassword" element={<ForgotPassword />} />
          <Route path="CodeSubmit" element={<CodeSubmit />} />
          <Route path="CreatePassword" element={<CreatePassword />} />
          <Route path="PasswordUpdated" element={<PasswordUpdated />} />
          <Route path="BusinessSignUp" element={<BusinessSignUp />} />
          <Route path="VerifyEmail" element={<VerifyEmail />} />
          <Route path="UpdateEmailVerifyEmail" element={<UpdateEmailVerifyEmail />} />
          <Route path="Home" element={<Home />} />
          
          <Route path="Settings" element={<Settings/>} />
        
          <Route path="About" element={<About />} />
          <Route path="TicketGuard" element={<TicketGuard />} />
          <Route path="Email" element={<Email />} />
          <Route path="Twitter" element={<Twitter />} />
          {/* <Route path="Home" element={<Test />} /> */}
          <Route path="CreateEvent" element={<CreateEvent />} />
          <Route path="EventDetails/:id" element={<EventDetails />} />
          <Route
            path="EventDetails/:id/AttendeeList"
            element={<AttendeeList />}
          />
          <Route path="EventDetails/:id/OrderList" element={<OrderList />} />
          <Route
            path="EventDetails/:id/OrderList/OrderDetails/:id"
            element={<OrderDetails />}
          />
          <Route
            path="EventDetails/:id/AttendeeList/AttendeeDetails/:id"
            element={<AttendeeDetails />}
          />
        </Routes>
    </Elements>
      )}
      <Loader />
      <Banner
        text={bannerContent?.text}
        headingText={bannerContent?.headingText}
        showBanner={bannerContent?.showBanner}
        type={bannerContent?.type}
        withIcon={bannerContent?.withIcon}
        withClose={bannerContent?.withClose}
        width={bannerContent?.width}
        position={bannerContent?.position}
        paddingLeft={bannerContent?.paddingLeft}
        style={bannerContent?.style}
        parentContainerStyle={bannerContent?.parentContainerStyle}
      />
    </>
  );
}

export default App;
